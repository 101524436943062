@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/1f6baf963b06842d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/c7071550daf62a5f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/f8fd3cfed3c9a064-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/ba9b8b4275920530-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/0412887224d6c282-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/e80dd3cd87e9062e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/4e74495e36b7245c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/66ab3bc34d84263d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: '__mohrFont_e860ea';
src: url(/_next/static/media/96ab16c40661f6b3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__mohrFont_Fallback_e860ea';src: local("Arial");ascent-override: 90.34%;descent-override: 45.53%;line-gap-override: 0.00%;size-adjust: 110.91%
}.__className_e860ea {font-family: '__mohrFont_e860ea', '__mohrFont_Fallback_e860ea'
}.__variable_e860ea {--font-mohr: '__mohrFont_e860ea', '__mohrFont_Fallback_e860ea'
}

